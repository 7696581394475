
import { defineComponent, onMounted, computed, ref, reactive, onBeforeUnmount, watch } from 'vue'

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Policy from '@/core/services/Callable/Policy';
import router from '@/router/clean';
import { store } from '@/store';
import Documents from '@/core/services/Callable/Documents';
import Validations from '@/core/services/etc/Validations';
import { FormInstance } from 'element-plus';
import { activeTab, leadEmployeeCategoryId, notification, rule } from '@/store/stateless';
import { updatePolicyDetailsProp, updatePolicyGroupProp } from "@/core/utils/policy.js";
import Etcs from '@/core/services/Callable/Etcs';
import { ElLoading } from 'element-plus'

export default defineComponent({
    name: 'policy-group-edit',

    setup() {

        const policyRuleFormRef = ref<FormInstance>()

        const policy = computed(() => {
            return store.state.policy.policy
        })

        const documents = computed(() => {
            return store.getters.getAllDocuments(2)
        })

        const email_activity = computed(() => {
            return store.state.policy.email_activity;
        });

        const disablePolicySaveButton = ref(false)
        const disablePolicyDraftSaveButton = ref(false)

        watch(() => policy.value, newdata =>{

            newdata.main_invoice.categories.map(x => {
                if(newdata.main_invoice.id == x.group_invoice_id) {
                    x.policy_start_date = newdata.policy_start_date
                }
                x.policy_end_date = newdata.policy_end_date

                return {
                    ...x
                }
            })

        }, {
            deep: true
        })

        onMounted(async () => {

            Validations.is_group = true

            const loading = ElLoading.service({
                target: '.card edit-group-policy',
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
            rule.customerDetails = 'insured_person'

            setCurrentPageBreadcrumbs("Edit Policy Details", [{ label: "Policy", value: '' }]);
            await policyDetails()
            await Etcs.getVat();

            // if(!policy.value.insurance_payment)
            // policy.value.insurance_payment = store.getters.getInsurancePayment(policy.value.main_invoice.payment_type)

            if(policy.value.entity_type == null)
                policy.value.entity_type = 1; // default corporate for grouop

            policyRef.value.focus()

            loading.close()


        })

        const isDocumentFetch = ref(false)

        const policyDetails = async () => {
            const policy_purchase_id = parseInt(router.currentRoute.value.params.policy_id.toString())
            const payload = { policy_purchase_id: policy_purchase_id }
            await Policy.getGroupPolicy(payload)
            activeTab.value = 0 
            await Policy.getEditPolicySetting()
            await fetchDocument()
            await fetchAmendDocument()
            isDocumentFetch.value = true
            await Policy.getGroupEmailActivity({policy_id : policy_purchase_id})
        }

        const rules = reactive({
            insured_person: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            policy_holder: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            emirates_id: [{ validator: Validations.emirates_id, trigger: ['blur','change','input'] }],
            policy_holder_emirates_id: [{ validator: Validations.emirates_id, trigger: ['blur','change','input'] }],
            policy_number: [{ validator: Validations.policy_number, trigger: ['blur','change','input'] }],
            policy_start_date: [{ validator: Validations.policy_start_date, trigger: ['blur','change','input'] }],
            policy_end_date: [{ validator: Validations.policy_end_date, trigger: ['blur','change','input'] }],
            insurance_payment: [{ validator: Validations.insurance_payment, trigger: ['blur','input', 'change'] }],
            entity_type: [{ validator: Validations.entity_type, trigger: ['blur','change','input'] }],
            trade_license: [{ validator: Validations.trade_license, trigger: ['blur','change'] }],
            trade_license_issue_date: [{ validator: Validations.trade_license_issue_date, trigger: ['blur','change'] }],
            trade_license_expiry_date: [{ validator: Validations.trade_license_expiry_date, trigger: ['blur','change'] }],
            // policy_end_date: [{ validator: Validations.policy_end_date, trigger: ['blur'] }],
            // policy_end_date: [{ validator: Validations.policy_end_date, trigger: ['blur'] }],
        })

        const saveAsDraft = async () => {
            disablePolicyDraftSaveButton.value = true;

            policy.value.documents = documents.value; // appendind documents

            const response = await Policy.updateGroupPolicy(policy.value)

            if(response.status == 200)
            {
                notification.success(
                    'Policy',
                    response.data.message
                );

                if(response.data.data.document_validation.length > 0)
                {
                    setTimeout(() => {
                        notification.warning(
                            'Policy',
                            response.data.data.document_validation,
                        );
                    }, 0);
                }
            }
            
            disablePolicyDraftSaveButton.value = false;

        }

        const isSubmitted = ref(false)
        const policyRef = ref()
        const policyGroupRef = ref()
        const policyCDRef = ref()
        
        const submitPolicy = async (formEl: FormInstance | undefined) => {

            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if (valid) {

                    // if(policy.value.is_amended == 1 && store.getters.getAmendValidation){
                    //     notification.warning(
                    //         'Policy',
                    //         'Please upload amend documents'
                    //     );
                    //     return
                    // }

                    disablePolicySaveButton.value = true;

                    policy.value.documents = documents.value; // appendind documents

                    const response = await Policy.submitGroupPolicy(policy.value)

                    if(response.status == 200)
                    {
                        notification.success(
                            'Policy',
                            response.data.message
                        );

                        window.scrollTo(0, 0);


                        if(policy.value.status == 4)
                            router.replace({ name: 'groupCompletedPolicies' })

                        await policyDetails()

                    }
                    else if(response.response.status == 422){
                        const errors = response.response.data.data;
                        let message = '';
                        for(let key in errors)
                        {
                            for (const msg of errors[key]) {
                                message += msg + '<br/>';
                            }

                        }
                        
                        notification.warning(
                                response.response.data.message,
                                message
                            );
                    }

                    disablePolicySaveButton.value = false;

                }
                else {
                    rule.customerDetails = Object.keys(fields)[0]
                    const destruct = rule.customerDetails.split('.')
                    if(rule.customerDetails.includes('main_invoice.categories')) {
                        activeTab.value = parseInt(destruct[2])
                    }
                    setTimeout(() => {
                        if(updatePolicyDetailsProp.includes(rule.customerDetails)) policyRef.value.focus()
                        else if(updatePolicyGroupProp.includes(rule.customerDetails)) {
                            policyGroupRef.value.focus()
                        }
                        else policyCDRef.value.focus()
                    }, 500);
                }
            
                
            })
        }

        const fetchDocument = async () => {
            await Documents.getDocuments({
                is_loaded: true,
                categories: ['standard'],
                lead_id: policy.value.lead_id,
                lead_employee_category_id: leadEmployeeCategoryId.value,
                policy_id: policy.value.policy_purchase_id
            })
        }

        onBeforeUnmount(() => {
            leadEmployeeCategoryId.value = 0
        })

        const fetchAmendDocument = async () => {
            await Documents.getAmendDocuments({
                policy_id: policy.value.policy_purchase_id,
                lead_employee_category_id: leadEmployeeCategoryId.value
            });
        };

        const revalidate = () => {
            // policyRuleFormRef.value?.clearValidate()
            // if(isSubmitted.value) {
            //     policyRuleFormRef.value?.validate((valid) => {
            //         if(!valid) {
            //             // 
            //         }
            //     })
            // }
        }

        const clearValidations = () => {
            setTimeout(() => {
                policyRuleFormRef.value?.clearValidate()
            }, 100);
        }

        const enableAllFields = computed(() => {
            const user  = JSON.parse(window.localStorage.getItem('user') || "")
            
            if(user && policy.value && user.role_id == 14 && policy.value.status == 4) return true

            return false
        })

        return {
            enableAllFields,
            isDocumentFetch,
            clearValidations,
            policy,
            rules,
            disablePolicySaveButton,
            disablePolicyDraftSaveButton,
            saveAsDraft,
            submitPolicy,
            policyRuleFormRef,
            revalidate,
            store,
            email_activity,
            policyGroupRef,
            policyRef,
            policyCDRef

        }
    },
})
